<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>

<!--        <h2 class="depTitle">新页面</h2>-->
<!--        <div>-->
<!--          <el-form class="twoForm" :inline="true">-->
<!--            <el-form-item label="时间：" class="timClas">-->
<!--              <el-date-picker-->
<!--                  v-model="startTime"-->
<!--                  type="month"-->
<!--                  format="yyyy 年 MM 月"-->
<!--                  value-format="yyyy-MM"-->
<!--                  @change="changestartTime"-->
<!--                  placeholder="选择时间">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="科室：">-->
<!--              <el-select v-model="typeValue" placeholder="请选择科室" clearable filterable @change="typeValueChange(typeValue)" class="xiangType">-->
<!--                <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </div>-->

        <el-table
            :data="tableData"
            style="width: 100%" border>
          <el-table-column
              prop="name"
              label="科室" align="center">
          </el-table-column>

          <el-table-column label="一级指标" align="center">
            <el-table-column label="二级指标" align="center">
              <el-table-column
                  prop="province"
                  label="三级指标" align="center">
              </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column label="医疗质量" align="center">
            <el-table-column label="功能数量" align="center">
              <el-table-column
                  prop="province"
                  label="医疗人员在岗率" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="医疗安全" align="center">
              <el-table-column
                  prop="province"
                  label="不良反应上报率" align="center">
              </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column label="社会效益" align="center">
            <el-table-column label="医疗费用" align="center">
              <el-table-column
                  prop="province"
                  label="次均医疗费增幅" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="耗材占比" align="center">
              <el-table-column
                  prop="province"
                  label="百元收入耗材占比" align="center">
              </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column label="运营效率" align="center">
            <el-table-column label="医疗效率" align="center">
              <el-table-column
                  prop="province"
                  label="平均每一医师每日负担诊疗人次" align="center">
              </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column label="可持续发展" align="center">
            <el-table-column label="新技术新项目" align="center">
              <el-table-column
                  prop="province"
                  label="驱动新业务项目" align="center">
              </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column
              prop="date"
              label="总分" align="center">
          </el-table-column>

          <el-table-column
              prop="date"
              label="操作" align="center">
            <template style="display: flex" slot-scope="scope">
              <el-button
                  size="mini"
                  type="primary"
              >填报</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      typeValue:"",
      depoptions:[],
      startTime:'',
      endTime:"",
      disabled:false,
      btnShow:true,
      fillTitle:'',
      // 填报
      Performance: {
        numOfPersonnel:'',
      },
      PerformanceRules: {
        numOfPersonnel: [
          {
            required: true,
            message: "请输入在岗人数",
            trigger: "blur",
          },
        ],
      },
      // 表格数据
      tableData: [{
        name: '妇产科',
      }, {
        name: '中医科',
      }, {
        name: '外科',
      }, {
        name: '内科',
      }, {
        name: '超声科',
      }, {
        name: '儿科',
      }],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      bascId:"",
      status1:"",
      status2:"",
      status3:"",
      status4:"",
      status5:""
    };
  },
  created() {
    this.getDateTime();
    // 科室数据查询
    this.findDepartment()
  },
  methods: {
    typeValueChange(val){
      this.typeValue=val
      this.getDepartmentNotPage();
    },
    async findDepartment(){
      let data = {
        hospitalId:window.sessionStorage.getItem("hospitalId")
      };
      let { data: res } = await this.$axios.findDepartment(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.depoptions = res.data;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.getDepartmentNotPage();
    },
    changestartTime(val){
        this.startTime=val
        this.getDepartmentNotPage();
    },
    indexMethod(index) {
      let limitpage = this.pageSize; // 每页条数
      let curpage = this.pageNum; // 当前页码
      return index + 1 + (curpage - 1) * limitpage;
    },
    async fillClick(row) {
      this.bascId=row.id
      this.status1=row.status1
      this.status2=row.status2
      this.status3=row.status3
      this.status4=row.status4
      this.status5=row.status5
      this.fillTitle='科室绩效核算'
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
      let data = {
        id: row.id
      };
      let { data: res } = await this.$axios.findById(data);
      // console.log(res)
      this.Performance=row
    },
    async lookClick(row){
      this.fillTitle='查看科室绩效'
      this.btnShow=false
      this.FillingVisible=true
      this.disabled=true
      let data = {
        id: row.id
      };
      let { data: res } = await this.$axios.findById(data);
      // console.log(res)
      this.Performance=row
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //  绩效核算
    async getDepartmentNotPage() {
      let data = {
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        pageType:'COUNT',//核算
        basePeriodTime:this.startTime,//时间
        departmentId:this.typeValue,
      };
      let { data: res } = await this.$axios.findCurrentYearAndMonthInformation(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onsubmit() {
      this.$refs.PerformanceRef.validate(async(valid) => {
        if (valid) {
          let data = _qs.stringify({
            id: this.bascId,//主键
            workload:this.Performance.workload,//工作量绩效
            checkup:this.Performance.checkup,//体检专项
            cancerScreen:this.Performance.cancerScreen,//两癌筛查
            medicalInsuranceFund:this.Performance.medicalInsuranceFund,//医保基金奖励
            highQualityCare:this.Performance.highQualityCare,//优质护理奖励
            qualityControl:this.Performance.qualityControl,//质控扣罚
            bonusSubsidy:this.Performance.bonusSubsidy,//奖金补助
            otherSpecial:this.Performance.otherSpecial,//体检专项(体检科)
            painfulDelivery:this.Performance.painfulDelivery,//阵痛分娩奖励
            nightDutyAllowance:this.Performance.nightDutyAllowance,//夜班费
            other:this.Performance.other,//其他
            personnelExpenditure:this.Performance.personnelExpenditure,//人员列支
            numOfPersonnel:this.Performance.numOfPersonnel,//在岗人数
            outCheckWorkload:this.Performance.outCheckWorkload,//外院化验工作量绩效
            calculateStatus:this.Performance.calculateStatus,//绩效核算状态
            status1:this.status1,
            status2:this.status2,
            status3:this.status3,
            status4:this.status4,
            status5:this.status5
          });
          let { data: res } = await this.$axios.calculate(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getDepartmentNotPage()
            this.FillingVisible=false
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        }
      });
    },
    close(){
      this.FillingVisible=false
    }
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 400px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
  /deep/ .boxTwo .el-input__inner{
    text-align: center!important;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

.twoForm {
  float: right;
  display: flex;
}
/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.fillTwo{
  display: flex;
  justify-content: space-around;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnSize button{
  width: 120px;
}

// 取消开始时间和结束时间的×符号
/deep/ .twoForm .timClas .el-input__suffix{
  display: none!important;
}
</style>
